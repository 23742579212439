import AbstractService from "./AbstractService";

export default class DownlinkService extends AbstractService {

    constructor() {
        super();
    }
    async sendCommand(id, alias) {
        const {code} = await this.request.post(this.config.apiUrl + "/v1/devices/" + id + "/downlink", {
            'alias': alias
        });

        return code === 201;
    }
}
