<script>

import DefaultCard from "../default/DefaultCard";
import ValveCardHeader from "./ValveCardHeader";
import ValveCardMetricList from "./ValveCardMetricList";

export default {
    name: "ValveCard",
    extends: DefaultCard,
    components: {
        HeaderComponent: ValveCardHeader,
        MetricList: ValveCardMetricList,
    },
}
</script>
