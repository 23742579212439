<template>
    <button @click="changeStatus()" class="btn btn-purple" :disabled="processing">
        <div v-if="processing" class="w-100">
            <div class="spinner-border spinner-border-sm text-white" role="status">
                <span class="sr-only"></span>
            </div>
            {{ $t('Выполнение...') }} {{ counter }}
        </div>
        <div v-else>
            {{ $t('Изменить положение') }}
        </div>
    </button>
</template>

<script>

import DownlinkService from "../../../services/DownlinkService";
import DeviceObject from "../../../objects/DeviceObject";

import TemporaryOption from "../../../classess/TemporaryOption";

const Downlinks = new DownlinkService();
const TempOption = new TemporaryOption();

export default {
    name: "ValveCardButton",
    props: {
        deviceObject: {
            type: DeviceObject,
            required: true
        },
        timeseries: {
            type: Array,
            required: false
        },
    },
    data() {
        return {
            processing: false,
            loop: null,
            tempOptionName: "valve_control_processing",
            delay: 60000,
            counter: null
        }
    },
    methods: {
        async changeStatus() {
            TempOption.set(this.tempOptionName, true, this.delay)
            this.counter = 60
            this.processing = true;

            const result = await Downlinks.sendCommand(this.deviceObject?.getId(), !this.status ? "open" : "close");
            if (result) {
                this.$toast.info(this.$t('Команда на изменение отправлена'));
            } else {
                TempOption.remove(this.tempOptionName);
                this.$toast.error(this.$t('Ошибка!'));
                this.processing = false;
                return;
            }
            this.done(this.counter)
        },
        done(counter) {
            this.counter = counter
            this.loop = setInterval(() => {
                this.counter--
                if ( this.counter <= 0 ) {
                    TempOption.remove(this.tempOptionName);
                    clearInterval(this.loop);
                    this.processing = false;
                    this.$eventBus.emit(this.$eventBus.REFRESH_CARD_TIMESERIES, this.deviceObject.getDevEui());
                    this.$toast.success(this.$t('Команда выполнена'));
                }
            }, 1000);
        }
    },
    computed: {
        status() {
            const res = this.timeseries
                ?.find(item => item.getSlug() === 'res')
                ?.getLatestData()
                ?.getValue();

            if (res === 1) {
                return true;
            }

            if (res === 0) {
                return false;
            }
            return null;
        },
    },
    mounted() {
        const isProcessing = TempOption.get(this.tempOptionName, false);
        const optionTtl = TempOption.getTtl(this.tempOptionName);

        if (isProcessing) {
            this.processing = true;
            this.done(Math.floor(optionTtl / 1000));
        }
    }
}
</script>

<style scoped lang="less">
    .btn-purple {
        min-width: 220px;
        font-size: 0.9rem;
    }
</style>
