<template>
    <div>
        <div class="deviceCardMetricItem d-flex align-items-center justify-content-between w-100 font-weight-500"
             style="height: 57px">
            <div class="w-50 p-3">
                <div class="d-flex justify-content-start align-items-center">
                    <div class="pr-2">
                        <div class="pr-1">
                        <span v-if="valveMetric.getPortIndex() !== null" class="metric-icon-index">
                            {{ valveMetric.getPortIndex() + 1 }}
                        </span>
                            <Icon :icon-name="valveMetric.getIcon()"
                                  width="23"
                                  height="23"
                                  :fill="valveMetric.getColor()"/>
                        </div>
                    </div>
                    <div>
                        {{ valveMetric.getName() }}
                    </div>
                </div>
            </div>
            <div class="w-17 px-3 pt-1 border-left h-100 d-flex align-items-center justify-content-center">
                <div v-if="valveMetric">
                    {{ status ? $t('Открыто') : $t('Закрыто')}}
                </div>
                <div class="d-flex align-items-center justify-content-center h-100" v-else>N/A</div>
            </div>
            <div class="d-flex align-items-center justify-content-center w-33 border-left h-100"
                 :class="{'bg-accident': metricHasAccident(valveMetric)}">
                <ValveCardButton :deviceObject="deviceObject" :timeseries="timeseries"/>
            </div>
        </div>
    </div>
</template>

<script>
import DefaultCardMetricList from "../default/DefaultCardMetricList";
import ValveCardButton from "./ValveCardButton";

export default {
    name: "ValveCardMetricList",
    components: {ValveCardButton},
    extends: DefaultCardMetricList,
    computed: {
        status() {
            const value = parseInt(this.getMetricLabel(this.valveMetric, 0))
            if (value === 1) {
                return true;
            }
            if (value === 0) {
                return false;
            }
            return null;
        },
        valveMetric() {
            return this.deviceMetrics.find(metric => metric.getSlug() === 'res');
        },
    }
}
</script>
